<template>
	<v-container fluid tag="section">
		<ti-card :icon="$icons.training">
			<template #title>Training</template>
			<template #title-actions-shown>
				<v-btn color="success" elevation="2" fab small :to="{name: 'TrainingNew'}">
					<v-icon>{{ $icons.new }}</v-icon>
				</v-btn>
			</template>

			<v-data-table :headers="headers" :items="training_courses" item-key="id" :search="search" @click:row="editItem" :item-class="() => 'hover-pointer'">
				<template v-slot:top>
					<v-row justify="end">
						<v-col cols="12" lg="6">
							<v-text-field
								v-model="search"
								label="Search"
								prepend-icon="mdi-magnify"
								class="mx-4"
							></v-text-field>
						</v-col>
					</v-row>
				</template>
			</v-data-table>
		</ti-card>
	</v-container>
</template>

<script>
import {sync} from "vuex-pathify";

export default {
	name: "TopicsIndex",
	computed:{
		training_courses: sync('training_courses/trainingCourses')
	},
	data: () => ({
		search: '',
		headers:[
			{
				text: 'Title',
				value: 'title',
			},
			{
				text: 'Slug',
				value: 'slug',
			},
		]
	}),
	beforeMount(){
		this.getTrainingCourses()
	},
	methods:{
		editItem(data){
			this.$router.push({name:'TrainingEdit', params: {id: data.id}})
		},
		getTrainingCourses(){
			this.$api.training_courses.index()
				.then(response => {
					this.training_courses = response.data
				})
				.catch(error => {
					console.log(error);
					this.$toast.add(error.message, 'error')
				})
		}
	}
}
</script>